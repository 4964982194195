import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const numberFormats = {
  fr: {
    currency: { style: 'currency', currency: 'EUR' },
    'currency-compact': { style: 'currency', currency: 'EUR', maximumSignificantDigits: 4, notation: 'compact' },
    compact: { maximumSignificantDigits: 4, notation: 'compact' }
  }
}

const i18n = createI18n({
  legacy: false,
  silentTranslationWarn: true,
  locale: navigator.language.split('-')[0],
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats
})

export default i18n
