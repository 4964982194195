import { authorize } from '@smartapp/auth-lib'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import * as firebaseui from 'firebaseui'
import { DateTime, Settings } from 'luxon'
import './registerServiceWorker'
import i18n from './i18n'

const locale = window.navigator.language.split('-')[0]
if (i18n.global.availableLocales.includes(locale)) i18n.global.locale.value = locale
Settings.defaultLocale = i18n.global.locale.value

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

export const globalProperties = {}
initializeApp(config)

const ui = new firebaseui.auth.AuthUI(getAuth())
const auth = getAuth()

onAuthStateChanged(auth, async firebaseUser => {
  if (firebaseUser) {
    authorize(config, firebaseUser, process.env.VUE_APP_BACKEND_SMART_AUTH)
      .then(({ user, roles, userCountry }) => {
        const today = DateTime.local()
        globalProperties.firebaseUser = firebaseUser
        globalProperties.user = user
        globalProperties.roles = roles
        globalProperties.userCountry = userCountry
        globalProperties.isUserFranchise = user?.site?.siteCurrentContract === 'FRA' || user?.employeeType === 'Affiliate'
        globalProperties.christmas = today.month === 12 && today.day > 3 && today.day < 29 // revoir pour l'an prochain
        
        import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
          createApp.createApp({ firebaseUser, user, roles })
        })
      })
      .catch(reason => {
        console.warn(reason)
        if (reason.status === 403) {
          globalProperties.firebaseUser = firebaseUser
          globalProperties.user = reason.user
          import(/* webpackChunkName: "createApp" */ './createApp').then(createApp => {
            createApp.createForbiddenApp({ ...reason, firebaseUser })
          })
        } else {
          window.location.href = '/error.html'
        }
      })
  } else {
    ui.start('#firebase-ui', {
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        {
          provider: 'oidc.pass',
          buttonColor: '#2A2A2D',
          iconUrl: 'null',
          fullLabel: i18n.global.t('loginTo'),
          providerName: 'Mesh'
        }
      ]
    })
  }
})